<template>
    <div>
        <div class="text-xs-right" v-if="FAQs.length > 6">
            <v-pagination :length="6" v-model="page" :total-visible="4" circle></v-pagination>
        </div>

        <v-card color="white" style="" v-for="item in FAQs"
            :key="item.key"
            :to="item.get_link" 
        >
            <v-card-text style="font-size: 12px; cursor: pointer">
                <h2 class="blue--text">{{item.name}}</h2>
                <p class="pa-0 ma-0">{{item.description|truncate(150, '...')}} </p>
                <v-layout row wrap>
                    <v-flex xs6 text-xs-left>
                        <span class="dark--text font-weight-bold">Rating:</span><span> {{item.ratings}}</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right>
                        <span class="dark--text font-weight-bold">Likes:</span><span> {{item.likes}}</span>
                    </v-flex>
                    <v-flex xs6 text-xs-left>
                        <span class="dark--text font-weight-bold ">Category:</span><span> {{item.category}}</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right>
                        <span class="dark--text font-weight-bold ">Dislikes:</span><span> {{item.dislikes}}</span>
                    </v-flex>
                </v-layout>
            </v-card-text>
        <v-divider></v-divider>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                page: 1,
                FAQs: [],
            }
        },
        methods:{
            getFAQs(){
                const feature = this.$route.params.types  // get campaign category from url and send request to server
                axios
                    .get('/api/v1/home/get/faqs/'+feature+'/info/')
                    .then(response => {
                        this.FAQs = response.data  // get the data and fill into FAQs
                        console.log(this.FAQs)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getFAQs();
        },
        
        watch: {
            '$route': function(to, from) {
                this.getFAQs()
            }
        },
    }
</script>
